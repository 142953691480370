import lottie from "lottie-web";

// Component imports
require("./modal");
require("./audioPlayer");

let currentSlide = 0;
let scroll = false;
let scrollBehavior = "smooth";
export let reducedMotion = false;

// DOM ELEMENTs
const accordionButtons = document.querySelectorAll(".accordion");
const accordionPanels = document.querySelectorAll(".panel");
const progressBars = document.querySelectorAll(".apha-progress-bar-container");
const progressBarButtons = document.querySelectorAll(
  ".apha-progress-bar-button"
);
const resetSlideShowButton = document.getElementById("reset-slideshow");
const startArrowButton = document.getElementById("start-arrow");
const scrollToggleButtons = document.querySelectorAll(
  ".apha-reduced-motion-toggle"
);
const vennDiagramButtons = document.querySelectorAll(".apha-venn-button");
const vennDiagramButtonCircles = document.querySelectorAll(".apha-venn-circle");
const vennDiagramButtonText = document.querySelectorAll(".apha-venn-text");
const mediaQuery = window.matchMedia("(prefers-reduced-motion: reduce)");
const slide2Content = document.getElementById("slide2Content");

// Lottie animations
const slideSixAnimation = lottie.loadAnimation({
  container: document.getElementById("apha-slide6-preventable-animation"), // the dom element that will contain the animation
  renderer: "svg",
  loop: true,
  autoplay: true,
  path: "./assets/animations/data.json", // the path to the animation json
});

const slideTwoAnimation = lottie.loadAnimation({
  container: document.getElementById("apha-slide2-spotlights-animation"), // the dom element that will contain the animation
  renderer: "svg",
  loop: false,
  autoplay: false,
  path: "./assets/animations/Spotlights.json", // the path to the animation json
});

/**
 * Restores all animations
 */
const animationRestore = () => {
  slideTwoAnimation.goToAndStop(1, true);
  unShrinkCircles();
  slideSixAnimation.play();
};

/**
 * Stops all animations
 */
const animationStop = () => {
  slideSixAnimation.pause();
  shrinkCircles();
  slideTwoAnimation.goToAndStop(slideTwoAnimation.totalFrames - 1, true);
};

/**
 * Debounces a function call
 *
 * @param {function} func function to invoke
 * @param {number} delay delay in ms
 * @param {boolean} immediate start at leading or trailing edge
 * @returns function call
 */
export const debounce = (func, delay, immediate) => {
  let timerId;
  return (...args) => {
    const boundFunc = func.bind(this, ...args);
    clearTimeout(timerId);
    if (immediate && !timerId) {
      boundFunc();
    }
    const calleeFunc = immediate
      ? () => {
          timerId = null;
        }
      : boundFunc;
    timerId = setTimeout(calleeFunc, delay);
  };
};

/**
 * Opens and closes accordion panels
 * @param {MouseEvent} e click event
 */
const handleAccordianClick = (e, accordionButton) => {
  let target;

  if (!accordionButton) {
    if (
      e.target.className === "accordion" ||
      e.target.className === "accordion accordion-active"
    ) {
      target = e.target;
    } else {
      target = e.target.parentNode;
    }
  } else {
    target = accordionButton;
  }

  const arrow = target.childNodes[1];

  const buttonNumber = target.id.charAt(22);

  vennDiagramButtonCircles.forEach((circle) =>
    circle.classList.remove("active")
  );
  vennDiagramButtonText.forEach((text) => text.classList.remove("active"));
  const circle = document.getElementById(`venn-circle${buttonNumber}`);
  const text = document.getElementById(`venn-text${buttonNumber}`);

  if (target.className !== "accordion accordion-active") {
    // remove active state from all buttons and set aria-expanded to false
    accordionButtons.forEach((button) => {
      button.classList.remove("accordion-active");
      button.setAttribute("aria-expanded", "false");
    });

    // set aria-expanded for the target to true
    target.setAttribute("aria-expanded", "true");

    // remove height from any open panels
    accordionPanels.forEach((panel) => {
      panel.style.maxHeight = null;
    });

    circle.classList.add("active");
    text.classList.add("active");
  } else {
    target.setAttribute("aria-expanded", "false");
  }

  target.classList.toggle("accordion-active");
  arrow.classList.toggle("open");

  const panel = target.nextElementSibling;

  if (panel.style.maxHeight) {
    panel.style.maxHeight = null;
  } else {
    panel.style.maxHeight = `${panel.scrollHeight}px`;
  }
};

/**
 * Handles a click the venn diagram image buttons
 * @param {MouseEvent} e click event
 */
const handleVennDiagramClick = (e) => {
  const accordionHeaderId = e.currentTarget.getAttribute("data-id");

  const accordionHeader = document.getElementById(accordionHeaderId);

  handleAccordianClick(null, accordionHeader);
};

/**
 * Handles up and down keypress in slideshow mode
 * @param {MouseEvent} e click event
 * @returns
 */
const handleKeypress = (e) => {
  // do nothing if on mobile view
  if (
    window.innerWidth <= 992 ||
    window.innerHeight <= 750 ||
    scroll === true
  ) {
    return;
  }

  const key = e.code;
  const ieKeyCode = e.keyCode;

  if (key === "ArrowUp" || ieKeyCode === 38) {
    e.preventDefault();
    slideUp();
  } else if (key === "ArrowDown" || ieKeyCode === 40) {
    e.preventDefault();
    slideDown();
  }
};

/**
 * Handles clicks on progress bar buttons
 * @param {MouseEvent} e click event
 */
const handleProgressBarButtonClick = (e) => {
  const slideNumber = e.currentTarget.dataset.slide;

  if (parseInt(slideNumber) === 0) {
    resetSlideShow();
    return;
  }

  if (currentSlide === slideNumber) {
    return;
  }

  if (currentSlide < slideNumber) {
    slideDown(slideNumber);
  } else {
    slideUp(slideNumber);
  }
};

/**
 * Handles mouse wheel scroll up and down while in slideshow mode
 * @param {MouseEvent} e wheel event
 */
const handleWheelEvent = (e) => {
  // do nothing if on mobile view
  if (
    window.innerWidth <= 992 ||
    window.innerHeight <= 750 ||
    scroll === true
  ) {
    return;
  }

  const scrollAmount = e.deltaY;

  if (scrollAmount >= 1) {
    slideDown();
  }

  if (scrollAmount <= -1) {
    slideUp();
  }
};

/**
 * Moves to the top of the slideshow
 */
const resetSlideShow = () => {
  window.scrollTo({ left: 0, top: 0, behavior: scrollBehavior });
  currentSlide = 0;
};

/**
 * Navigates down one slide or to a certain slide #
 * @param {int} slideNumber the slide to navigate to
 */
const slideDown = (slideNumber) => {
  // do nothing if on the last slide and there is no slide number argument (down arrow pressed)
  if (currentSlide === 9 && !slideNumber) {
    return;
  }

  // set the current slide to the next slide
  if (slideNumber) {
    currentSlide = parseInt(slideNumber);
  } else {
    currentSlide++;
  }

  // get the position of the next slide
  const topOfNextSlide = document
    .getElementById(`slide${currentSlide}`)
    .getBoundingClientRect().top;
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  const yPosition = topOfNextSlide + scrollTop;

  // different scrollTo method invocations for ie/ not ie
  if (window.document.documentMode) {
    window.scrollTo(0, yPosition);
  } else {
    window.scrollTo({ left: 0, top: yPosition, behavior: scrollBehavior });
  }

  if (currentSlide === 2 && !reducedMotion) {
    setTimeout(() => {
      slideTwoAnimation.play();
      slide2Content.classList.add("fade-in");
    }, 600);
  }

  if (currentSlide === 7 && !reducedMotion) {
    setTimeout(() => {
      shrinkCircles();
    }, 500);
  }
};

/**
 * Navigates up one slide or to a certain slide #
 * @param {int} slideNumber the slide to navigate to
 */
const slideUp = (slideNumber) => {
  if (currentSlide === 0) {
    return;
  }

  // set the current slide to the next slide
  if (slideNumber) {
    currentSlide = parseInt(slideNumber);
  } else {
    currentSlide--;
  }

  const topOfPreviousSlide = document
    .getElementById(`slide${currentSlide}`)
    .getBoundingClientRect().top;
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  const yPosition = topOfPreviousSlide + scrollTop;
  if (window.document.documentMode) {
    window.scrollTo(0, yPosition);
  } else {
    window.scrollTo({ left: 0, top: yPosition, behavior: scrollBehavior });
  }

  if (currentSlide === 2 && !reducedMotion) {
    setTimeout(() => {
      slideTwoAnimation.play();
      slide2Content.classList.add("fade-in");
    }, 500);
  }

  if (currentSlide === 7 && !reducedMotion) {
    setTimeout(() => {
      shrinkCircles();
    }, 500);
  }
};

/**
 * Starts the shrinking circles animation on slide 7
 */
const shrinkCircles = () => {
  const circles = document.querySelectorAll(".circle");
  circles.forEach((circle) => {
    circle.classList.add("shrink");
  });
};

/**
 * Detects and qualifies swipes on a touchscreen
 * @param {function} callback function to call after touch event ends
 */
const swipedetect = (callback) => {
  let swipedir;
  let startX;
  let startY;
  let distX;
  let distY;
  const threshold = 150; // required min distance traveled to be considered swipe
  const restraint = 100; // maximum distance allowed at the same time in perpendicular direction
  const allowedTime = 300; // maximum time allowed to travel that distance
  let elapsedTime;
  let startTime;
  const handleswipe = callback;

  document.addEventListener(
    "touchstart",
    (e) => {
      const touchobj = e.changedTouches[0];
      swipedir = "none";
      // dist = 0;
      startX = touchobj.pageX;
      startY = touchobj.pageY;
      startTime = new Date().getTime(); // record time when finger first makes contact with surface
    },
    false
  );

  document.addEventListener(
    "touchend",
    (e) => {
      const touchobj = e.changedTouches[0];
      distX = touchobj.pageX - startX;
      // get horizontal dist traveled by finger while in contact with surface
      distY = touchobj.pageY - startY;
      // get vertical dist traveled by finger while in contact with surface
      elapsedTime = new Date().getTime() - startTime; // get time elapsed
      if (elapsedTime <= allowedTime) {
        // first condition for awipe met
        if (Math.abs(distX) >= threshold && Math.abs(distY) <= restraint) {
          // 2nd condition for horizontal swipe met
          swipedir = distX < 0 ? "left" : "right"; // if dist traveled is negative, it indicates left swipe
        } else if (
          Math.abs(distY) >= threshold &&
          Math.abs(distX) <= restraint
        ) {
          // 2nd condition for vertical swipe met
          swipedir = distY < 0 ? "up" : "down"; // if dist traveled is negative, it indicates up swipe
        }
      }
      handleswipe(swipedir);
    },
    false
  );
};

/**
 * Moves to slide 1 when the arrow on slide 0 is clicked
 */
const startSlideshow = () => {
  slideDown();
};

/**
 * Toggles slideshow mode on and off
 */
const toggleReducedMotion = (e) => {
  if (scroll === false) {
    reducedMotion = true;

    animationStop();
    scrollBehavior = "auto";

    scrollToggleButtons.forEach((button) => {
      button.innerHTML = "Reduced Motion ON";
    });
    document.querySelector("body").style.overflow = "auto";
    progressBars.forEach((progressBar) => {
      progressBar.classList.add("d-none");
    });
    slide2Content.classList.add("fade-in");
    scroll = true;
  } else if (scroll === true) {
    const targetSlide = e.target.dataset.slide;
    reducedMotion = false;

    animationRestore();
    scrollBehavior = "smooth";

    scrollToggleButtons.forEach((button) => {
      button.innerHTML = "Reduced Motion OFF";
    });
    document.querySelector("body").style.overflow = "hidden";
    progressBars.forEach((progressBar) => {
      progressBar.classList.remove("d-none");
    });

    scroll = false;
    slideDown(targetSlide);
    turnOnReducedMotionWithTab();
  }
};

/**
 * Turns off slideshow mode when tab is pressed.
 */
const turnOnReducedMotionWithTab = () => {
  document.addEventListener(
    "keydown",
    function tabPress(e) {
      const key = e.keyCode || e.code;

      if (key === 9 || key === "Tab") {
        toggleReducedMotion();
        document.removeEventListener("keydown", tabPress, false);
      }
    },
    false
  );
};

/**
 * Resets the shrinking circles animation on slide 7
 */
const unShrinkCircles = () => {
  const circles = document.querySelectorAll(".circle");
  circles.forEach((circle) => {
    circle.classList.remove("shrink");
  });
};

/**
 * Runs when the script is loaded
 */
(() => {
  // Starts swipe detection
  swipedetect((swipedir) => {
    if (swipedir === "down") {
      slideUp();
    }
    if (swipedir === "up") {
      slideDown();
    }
  });

  // Starts the event listener for the tab to toggle reduced motion
  turnOnReducedMotionWithTab();

  // Scrolls to the top before refresh
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };

  // Turns on reduced motion if enabled in OS
  window.onload = () => {
    if (mediaQuery.matches) {
      toggleReducedMotion();
    }
  };
})();

// EVENT LISTENERS
document.addEventListener("keydown", handleKeypress);
document.addEventListener("scroll", () => {
  if (
    !reducedMotion &&
    (window.innerWidth < 992 || window.innerHeight <= 750)
  ) {
    const top = window.pageYOffset || document.documentElement.scrollTop;
    const slideTwoLottie = document.querySelector(
      ".apha-slide2-image-animated-container"
    );
    const slideTwoLottieTop =
      slideTwoLottie.getBoundingClientRect().top +
      top -
      window.innerHeight +
      400;
    const slideSevenAnimation = document.querySelector(
      ".apha-shrinking-circles"
    );
    const slideSevenAnimationTop =
      slideSevenAnimation.getBoundingClientRect().top +
      top -
      window.innerHeight +
      400;

    if (top > slideTwoLottieTop) {
      setTimeout(() => {
        slideTwoAnimation.play();
        slide2Content.classList.add("fade-in");
      }, 500);
    }

    if (top > slideSevenAnimationTop) {
      setTimeout(() => {
        shrinkCircles();
      }, 500);
    }
  }
});
document.addEventListener("wheel", debounce(handleWheelEvent, 250, true));
vennDiagramButtons.forEach((button) => {
  button.addEventListener("click", handleVennDiagramClick);
});
accordionButtons.forEach((button) => {
  button.addEventListener("click", handleAccordianClick);
});
progressBarButtons.forEach((button) => {
  button.addEventListener("click", handleProgressBarButtonClick);
});
resetSlideShowButton.addEventListener("click", resetSlideShow);
scrollToggleButtons.forEach((button) => {
  button.addEventListener("click", toggleReducedMotion);
});
startArrowButton.addEventListener("click", startSlideshow);
