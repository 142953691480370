// Imports
import { debounce, reducedMotion } from './main';

// DOM ELEMENTS
const understandingCloseButtons = document.getElementsByClassName(
  'understanding-close-button',
);
const understandingOpenButtons = document.getElementsByClassName(
  'understanding-button',
);
const strategyCloseButton = document.querySelector('.strategy-close-button');
const strategyOpenButton = document.getElementById('strategy-button');

// list of tabbable elements
const CANDIDATES = `
  a, button, input, select, textarea, svg, area, details, summary,
  iframe, object, embed, 
  [tabindex], [contenteditable]
`;
let focusTrap;
// variable for close on escape function so event listener can be removed
let closeFunction;
const openModals = [];
const windowWidth = window.innerWidth;

/**
 * Closes all open modals
 */
const closeAllModals = () => {
  if (!document.getElementById('strategy-modal').classList.contains('d-none')) { closeStrategyModal(); }
  openModals.forEach((modal) => {
    closeReducedMotionModal(modal);
  });
};

/**
 * Closes an open understanding modal with animation for slide-show view
 * @param {HTMLElement} modal The modal to close
 * @param {HTMLElement} card The card which contains the modal button
 */
const closeAnimatedFullscreenModal = (modal, card) => {
  const finalWidth = card.getBoundingClientRect().width - 10;

  modal.firstElementChild.style.opacity = '0';
  setTimeout(() => {
    modal.style.width = `${finalWidth}px`;
  }, 500);
  setTimeout(() => {
    modal.style.opacity = '0';
  }, 1000);
  setTimeout(() => {
    modal.style.width = null;
    modal.style.height = null;
    modal.style.top = null;
    modal.style.left = null;
    modal.classList.add('d-none');
    modal.firstElementChild.classList.add('d-none');
    modal.classList.remove('motion-off');
    modal.firstElementChild.classList.remove('motion-off');
  }, 1500);
};

/**
 * Closes an open understanding modal with animation for mobile
 * @param {HTMLElement} modal The modal to close
 * @param {HTMLElement} card The card which contains the modal button
 */
const closeAnimatedMobileModal = (modal, card) => {
  const windowY = window.scrollY || window.pageYOffset;
  const yFinal = card.getBoundingClientRect().bottom + windowY + 5;

  modal.firstElementChild.style.opacity = '0';
  setTimeout(() => {
    modal.style.height = '0';
    modal.style.top = `${yFinal}px`;
  }, 500);
  setTimeout(() => {
    modal.style.opacity = '0';
    modal.style.width = null;
    modal.style.height = null;
    modal.style.top = null;
    modal.style.left = null;
    modal.classList.add('d-none');
    modal.firstElementChild.classList.add('d-none');
    modal.classList.remove('motion-off');
    modal.firstElementChild.classList.remove('motion-off');
  }, 1000);
};

/**
 * Closes an open understanding modal without animation
 * @param {HTMLElement} modal the modal to close
 */
const closeReducedMotionModal = (modal) => {
  modal.style.opacity = '0';
  modal.firstElementChild.style.opacity = '0';

  modal.style.width = null;
  modal.style.height = null;
  modal.style.top = null;
  modal.style.left = null;
  modal.classList.add('d-none');
  modal.firstElementChild.classList.add('d-none');
  modal.classList.remove('motion-off');
  modal.firstElementChild.classList.remove('motion-off');

  if (focusTrap) { focusTrap.release(); }
};

/**
 * Closes modal windows when the escape key is pressed, releases focus trap, and removes esc
 * key event listener
 * @param {MouseEvent} e click event
 * @param {HTMLElement} modal
 * @param {function} _close
 * @returns
 */
const closeOnEscape = (e, modal, _close) => {
  if (e.keyCode !== 27) {
    return;
  }

  if (modal.id === 'strategy-modal') {
    closeStrategyModal();
    return;
  }

  const targetCard = modal.querySelector('button').dataset.card;
  const card = document.getElementById(targetCard);
  const cardButton = document.getElementById(`${modal.id}-button`);

  if (!reducedMotion && window.innerWidth >= 992) {
    closeAnimatedFullscreenModal(modal, card);
  }

  if (!reducedMotion && window.innerWidth < 992) {
    closeAnimatedMobileModal(modal, card);
  }

  if (reducedMotion) {
    closeReducedMotionModal(modal);
  }

  if (focusTrap) { focusTrap.release(); }

  cardButton.focus();

  document.removeEventListener('keydown', _close, true);
};

/**
 * Handles the button click to close understanding modals
 * @param {MouseEvent} e click event
 */
const closeUnderstandingModal = (e) => {
  const _close = closeFunction;

  const closeButton = e.currentTarget;
  const modalToClose = closeButton.dataset.modal;
  const cardId = closeButton.dataset.card;
  const modal = document.getElementById(modalToClose);
  const card = document.getElementById(cardId);
  const cardButton = document.getElementById(`${modalToClose}-button`);

  if (!reducedMotion && window.innerWidth >= 992) {
    closeAnimatedFullscreenModal(modal, card);
  }

  if (!reducedMotion && window.innerWidth < 992) {
    closeAnimatedMobileModal(modal, card);
  }

  if (reducedMotion) {
    closeReducedMotionModal(modal);
  }

  if (focusTrap) { focusTrap.release(); }
  cardButton.focus();

  document.removeEventListener('keydown', _close, true);
};

/**
 * Handles the button click to open understanding modals
 * @param {MouseEvent} e click event
 */
const openUnderStandingModal = (e) => {
  const targetModal = e.currentTarget.dataset.id;
  const modalContainer = document.getElementById(targetModal);
  const modalContent = modalContainer.firstElementChild;
  const cardsContainer = document.getElementById('understanding-content');
  const targetCard = e.currentTarget.dataset.card;
  const card = document.getElementById(targetCard);

  openModals.push(modalContainer);

  focusTrap = trapFocus(modalContainer);

  if (!reducedMotion && window.innerWidth >= 992) {
    const initialWidth = card.getBoundingClientRect().width - 10;
    const height = card.getBoundingClientRect().height - 10;

    const windowX = window.scrollX || window.pageXOffset;
    const windowY = window.scrollY || window.pageYOffset;

    const x = document.getElementById('understanding-aces-card').getBoundingClientRect()
      .left
      + windowX
      + 5;
    const y = document.getElementById('understanding-aces-card').getBoundingClientRect()
      .top
      + windowY
      + 5;

    const finalWidth = cardsContainer.scrollWidth - 10;

    modalContainer.style.height = `${height}px`;
    modalContainer.style.width = `${initialWidth}px`;
    modalContainer.style.top = `${y}px`;
    modalContainer.style.left = `${x}px`;
    modalContainer.classList.remove('d-none');
    document.body.offsetHeight;
    modalContainer.style.opacity = '1';
    modalContainer.style.width = `${finalWidth}px`;

    // setTimeout(() => {
    //   modalContainer.style.width = finalWidth + "px";
    // }, 200);

    setTimeout(() => {
      modalContent.classList.remove('d-none');
      document.body.offsetHeight;
      modalContent.style.opacity = '1';
    }, 500);
  }

  if (reducedMotion && window.innerWidth >= 992) {
    const width = cardsContainer.scrollWidth - 10;
    const height = cardsContainer.scrollHeight - 10;

    const windowX = window.scrollX || window.pageXOffset;
    const windowY = window.scrollY || window.pageYOffset;

    const x = document.getElementById('understanding-aces-card').getBoundingClientRect()
      .left
      + windowX
      + 5;
    const y = document.getElementById('understanding-aces-card').getBoundingClientRect()
      .top
      + windowY
      + 5;

    modalContainer.style.height = `${height}px`;
    modalContainer.style.width = `${width}px`;
    modalContainer.style.top = `${y}px`;
    modalContainer.style.left = `${x}px`;
    modalContainer.style.opacity = '1';
    modalContent.style.opacity = '1';
    modalContainer.classList.add('motion-off');
    modalContent.classList.add('motion-off');
    modalContainer.classList.remove('d-none');
    modalContent.classList.remove('d-none');
  }

  if (reducedMotion && window.innerWidth < 992) {
    const width = card.scrollWidth - 10;
    const height = card.scrollHeight - 10;

    const windowX = window.scrollX || window.pageXOffset;
    const windowY = window.scrollY || window.pageYOffset;

    const x = card.getBoundingClientRect().left + windowX + 5;
    const y = card.getBoundingClientRect().top + windowY + 5;

    modalContainer.style.height = `${height}px`;
    modalContainer.style.width = `${width}px`;
    modalContainer.style.top = `${y}px`;
    modalContainer.style.left = `${x}px`;
    modalContainer.style.opacity = '1';
    modalContent.style.opacity = '1';
    modalContainer.classList.add('motion-off');
    modalContent.classList.add('motion-off');
    modalContainer.classList.remove('d-none');
    modalContent.classList.remove('d-none');
  }

  if (!reducedMotion && window.innerWidth < 992) {
    const width = card.scrollWidth - 10;
    const height = card.scrollHeight - 10;

    const windowX = window.scrollX || window.pageXOffset;
    const windowY = window.scrollY || window.pageYOffset;

    const x = card.getBoundingClientRect().left + windowX + 5;
    const yInitial = card.getBoundingClientRect().bottom + windowY + 5;
    const yFinal = card.getBoundingClientRect().top + windowY + 5;

    modalContainer.style.height = `${0}px`;
    modalContainer.style.width = `${width}px`;
    modalContainer.style.top = `${yInitial}px`;
    modalContainer.style.left = `${x}px`;

    modalContainer.classList.remove('d-none');

    modalContainer.style.opacity = '1';
    document.body.offsetHeight;
    modalContainer.style.height = `${height}px`;
    modalContainer.style.top = `${yFinal}px`;

    setTimeout(() => {
      modalContent.classList.remove('d-none');
      document.body.offsetHeight;
      modalContent.style.opacity = '1';
    }, 500);
  }

  document.addEventListener(
    'keydown',
    function _close(event) {
      this.closeFunction = _close;
      closeOnEscape(event, modalContainer, _close);
    },
    true,
  );
};

/**
 * Handles the button click to open the strategy modal
 */
const openStrategyModal = () => {
  const modal = document.getElementById('strategy-modal');
  focusTrap = trapFocus(modal);

  modal.classList.remove('d-none');

  document.addEventListener(
    'keydown',
    function _close(event) {
      this.closeFunction = _close;
      closeOnEscape(event, modal, _close);
    },
    true,
  );
};

/**
 * Handles the button click to open strategy modal
 * @param {MouseEvent} e click event
 */
const closeStrategyModal = () => {
  const _close = closeFunction;
  const modal = document.getElementById('strategy-modal');
  modal.classList.add('d-none');

  if (focusTrap) { focusTrap.release(); }

  strategyOpenButton.focus();

  document.removeEventListener('keydown', _close, true);
};

/**
 * Creates a focus trap on a modal window.  Removes the tabindex attribute from all HTML elements
 * @param {HTMLElement} focusNode the node to apply the focus trap
 * @param {HTMLElement} rootNode the root node (set to document as default)
 * @returns function to release the focus trap
 */
const trapFocus = (focusNode, rootNode = document) => {
  const nodes = [...rootNode.querySelectorAll(CANDIDATES)].filter(
    (node) => !focusNode.contains(node) && node.getAttribute('tabindex') !== '-1',
  );
  nodes.forEach((node) => node.setAttribute('tabindex', '-1'));
  return {
    release() {
      nodes.forEach((node) => node.removeAttribute('tabindex'));
    },
  };
};

// Event Listeners
$(() => {
  window.addEventListener('resize', debounce(() => {
    if (window.innerWidth !== windowWidth) {
      closeAllModals();
    }
  }, 1000, true));
  understandingOpenButtons.forEach((button) => {
    button.addEventListener('click', openUnderStandingModal);
  });
  understandingCloseButtons.forEach((button) => {
    button.addEventListener('click', closeUnderstandingModal);
  });
  strategyCloseButton.addEventListener('click', closeStrategyModal);
  strategyOpenButton.addEventListener('click', openStrategyModal);
});
